import { API_URL } from '@/utils/dataFetching/apiUrl'
import { apiDelete, apiPatch, apiPost } from '@/utils/dataFetching'
import {
  type QueryClient,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import {
  type ValidAddress,
  type Address,
  type BaseAddress,
  type DeleteAddressResponse,
} from '@/services/Addresses/types'
import { getAddressesQueryKey } from '@/services/Addresses/queries'
import { useCustomerAddressesQuery } from '@/services/Addresses/graphql/customerAddresses.generated'
import { userIsDoNotShareSellTA } from '@/services/DataPrivacy/utils'
import { useMutationHandleOptOutRestrictions } from '@/services/DataPrivacy/mutations'

const apiUrl = API_URL.MEMBER_API

const useOnSuccessAddress = (queryClient: QueryClient) => {
  const invalidateAddressQuery = () =>
    queryClient.invalidateQueries({
      queryKey: [useCustomerAddressesQuery.rootKey],
    })
  const { mutateAsync: handleOptOutRestrictions } =
    useMutationHandleOptOutRestrictions(invalidateAddressQuery)

  return (address: Address) => {
    if (userIsDoNotShareSellTA()) {
      invalidateAddressQuery()
    } else {
      return handleOptOutRestrictions({
        state: address.state,
        zip_code: address.zip_code,
      })
    }
  }
}

export const useMutationAddAddress = () => {
  const queryClient = useQueryClient()
  const onSuccessAddress = useOnSuccessAddress(queryClient)

  return useMutation({
    mutationFn: (address: ValidAddress) =>
      apiPost<Address>({
        config: {
          url: 'api/v1/customer_addresses.json',
          data: address,
        },
        options: { apiUrl },
        fetcherName: 'useMutationAddAddress',
      }),
    onSuccess: (address) => {
      queryClient.setQueryData<Address[]>(
        getAddressesQueryKey(),
        (addresses) => [address, ...(addresses || [])]
      )
      onSuccessAddress(address)
    },
  })
}

export const useMutationUpdateAddress = <
  TAddress extends Partial<BaseAddress>
>() => {
  const queryClient = useQueryClient()
  const onSuccessAddress = useOnSuccessAddress(queryClient)

  return useMutation({
    mutationFn: (address: TAddress) =>
      apiPatch<Address>({
        config: {
          url: `api/v1/customer_addresses/${address.id}`,
          data: address,
        },
        options: { apiUrl },
        fetcherName: 'useMutationUpdateAddress',
      }),
    onSuccess: (updatedAddress) => {
      queryClient.setQueryData<Address[]>(
        getAddressesQueryKey(),
        (addresses) => {
          return addresses?.map((address) => {
            if (address.id === updatedAddress.id) {
              return updatedAddress
            }
            return address
          })
        }
      )
      onSuccessAddress(updatedAddress)
    },
  })
}

export const useMutationDeleteAddress = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (addressId: number) =>
      apiDelete<DeleteAddressResponse>({
        config: {
          url: `api/v1/customer_addresses/${addressId}`,
        },
        options: {
          apiUrl,
        },
        fetcherName: 'useMutationDeleteAddress',
      }),
    onSuccess: (_, addressId) => {
      queryClient.setQueryData<Address[]>(getAddressesQueryKey(), (addresses) =>
        addresses?.filter((address) => address.id !== addressId)
      )
    },
  })
}
