import { useMutation, useQueryClient } from '@tanstack/react-query'
import { apiPatch } from '@/utils/dataFetching'
import { API_URL } from '@/utils/dataFetching/apiUrl'
import { type CustomerBaseResponse } from '@/services/User/types'
import { type SelectStoreParams } from '@/services/ShoppingStore/types'
import { useUserId } from '@/services/User/hooks'
import { DELIVERY_TYPES } from '@/constants/global'
import { routes } from '@shared/constants/routes'
import { UserQuery } from '@/services/User/queries'
import { useRouter } from 'next/router'
import { useMutationUpdateAddress } from '@/services/Addresses/mutations'
import { getAddresses } from '@/services/Addresses/utils'
import { cartOOSExpandedAtom } from '@/state/Cart/atoms'
import { useClearActiveOrder } from '@/state/SelectedActiveOrder/hooks'
import { useSetAtom } from 'jotai'

export const SelectStoreMutationKey = 'Select store'

export const useMutationSelectStore = () => {
  const queryClient = useQueryClient()
  const userId = useUserId() ?? 0
  const router = useRouter()
  const clearActiveOrder = useClearActiveOrder()
  const { mutateAsync: updateAddress } = useMutationUpdateAddress()
  const setIsCartOOSExpanded = useSetAtom(cartOOSExpandedAtom)
  return useMutation({
    mutationKey: [SelectStoreMutationKey],
    mutationFn: (params: SelectStoreParams) => {
      const payload = {
        store_id: params.storeId,
        store_location_id: params.locationId,
        default_shopping_address_id: params.addressId,
        order_delivery_type: params.isPickup
          ? DELIVERY_TYPES.PICKUP
          : DELIVERY_TYPES.STANDARD,
      }
      return apiPatch<CustomerBaseResponse>({
        config: { url: `api/v1/customers/${userId}`, data: payload },
        options: { apiUrl: API_URL.MEMBER_API },
        fetcherName: 'useMutationSelectStore',
      })
    },
    onSuccess: async (response, params) => {
      const { shouldClearCurrentActiveOrder = true } = params

      if (shouldClearCurrentActiveOrder) {
        clearActiveOrder()
      }

      setIsCartOOSExpanded(true)

      if (params.isPickup) {
        const addresses = getAddresses(queryClient)
        const address = {
          ...addresses?.find((add) => add.id === params.addressId),
          store_location_id: response.store_location_id,
          pickup_store_location_id: params.locationId,
        }
        await updateAddress(address)
      }
      if (params.isRoutingHome) {
        router.push(routes.RETAILER_HOMEPAGE.url)
      }
      if (!params.doNotInvalidateUserQuery) {
        return queryClient.invalidateQueries({ queryKey: [UserQuery] })
      }
    },
  })
}
