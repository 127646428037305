import styled from 'styled-components'
import { Title1, Body1 } from '@/styles/typography'
import { breakpoints } from '@/styles/media'
import { Image } from '@/elements/Image/NextImage'
import { objectStorageUrl } from '@/utils/objectStorageUrl'

export const ValueCards = () => {
  const cards = [
    {
      src: objectStorageUrl('value_card_1.webp'),
      heading: 'Above and beyond.',
      text: 'Like your avocados a little firm?Shoppers with Shipt go the extra mile to ensure you get the things you want, exactly how you like them.',
    },
    {
      src: objectStorageUrl('value_card_2.webp'),
      heading: 'Same-day delivery.',
      text: 'Local stores and national chains you know and love, delivered directly to you same-day.',
    },
    {
      src: objectStorageUrl('value_card_3.webp'),
      heading: 'Real-time updates.',
      text: 'Your shopper will keep you updated every step of the way. From special requests to last-minute changes — they’re here to help.',
    },
  ]
  return (
    <ScrollWrapper>
      <GridWrapper>
        {cards.map((card) => {
          const { heading, text, src } = card
          return (
            <Card key={heading}>
              <ImageWrapper>
                <Image
                  src={src}
                  width={400}
                  height={200}
                  alt=""
                  style={{ width: 'auto', height: 'auto' }}
                />
              </ImageWrapper>
              <Content>
                <Title>{heading}</Title>
                <Body1>{text}</Body1>
              </Content>
            </Card>
          )
        })}
      </GridWrapper>
    </ScrollWrapper>
  )
}

const ScrollWrapper = styled.div`
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  @media ${breakpoints.medium} {
    overflow-x: auto;
  }
`

const GridWrapper = styled.div`
  display: grid;
  gap: 24px;

  @media ${breakpoints.medium} {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media ${breakpoints.mediumOnly} {
    padding: 0 2.25rem;
    float: left;
  }

  @media ${breakpoints.large} {
    padding: 0;
  }
`

const Card = styled.div`
  background-color: ${({ theme }) => theme.green};
  border-radius: 8px;
  padding: 16px 10px 80px;
  text-align: center;
  width: 327px;
  height: 446px;

  &:nth-child(2) {
    background-color: ${({ theme }) => theme.oatmeal};
  }

  &:nth-child(3) {
    background-color: ${({ theme }) => theme.purple500};
    color: #fff;
  }

  @media ${breakpoints.medium} {
    width: 336px;
  }

  @media ${breakpoints.large} {
    width: 344px;
    height: 540px;
    padding: 64px 10px 80px;
  }

  @media ${breakpoints.xlarge} {
    width: 397px;
  }
`

const ImageWrapper = styled.div`
  margin-bottom: 1.5rem;
`

const Content = styled.div`
  margin: auto;
  max-width: 316px;
  padding: 0 8px;
`

const Title = styled(Title1)`
  margin-bottom: 16px;
`
